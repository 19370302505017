import { AbstractFormHandler } from './abstractFormHandler';
import { ZodError } from 'zod';
import { PrivateSellerInfo } from '../../models/interfaces/general';
import { FormDataWrapper } from '../../utils/FormDataWrapper';
import {
	SellerBankInfoSchema,
	SellerGeneralInfoSchema,
	sellerInfoAddressSchema,
} from '../../utils/validation';
import { SellerType } from '../../models/enums/general';

export class InfoSellerFormHandler extends AbstractFormHandler {
	public async submitForm(event: Event): Promise<void> {
		event.preventDefault();

		const form = event.target as HTMLFormElement;
		this.removeAlerts(form);
		const { loadingBtn, submitBtn } = this.showLoadingIndicators(form);
		const privateSellerInfo = this.getFormData(form);

		try {
			const zodError: ZodError = await this.superValidate(
				{
					schema: SellerGeneralInfoSchema,
					data: privateSellerInfo.general,
				},
				{
					schema: sellerInfoAddressSchema,
					data: privateSellerInfo.address,
				},
				{
					schema: SellerBankInfoSchema,
					data: privateSellerInfo.bank,
				},
			);

			if (zodError.issues.length > 0) {
				throw zodError;
			}

			const responseData = await this.submitData(privateSellerInfo);

			if (!responseData.success) {
				throw new Error(responseData.message);
			}

			if (responseData.redirect) {
				window.location.href = responseData.redirect;
				return;
			}
		} catch (error) {
			if (error instanceof ZodError) {
				this.createAlertForEachInput(form, error);
				form.scrollIntoView({ behavior: 'smooth', block: 'start' });
			} else {
				this.displayErrorMessage(form, this.extractErrorMessage(error));
			}
		} finally {
			this.hideLoadingIndicators(loadingBtn, submitBtn);
		}
	}

	private formatDateOfBirth(year: string, month: string, day: string): string {
		const formattedMonth = month.padStart(2, '0');
		const formattedDay = day.padStart(2, '0');
		return `${year}-${formattedMonth}-${formattedDay}`;
	}

	private getFormData(form: HTMLFormElement): PrivateSellerInfo {
		const formDataWrapper = new FormDataWrapper(form);

		return {
			address: this.getAddressInfo(formDataWrapper),
			bank: this.getBankInfo(formDataWrapper),
			general: this.getGeneralInfo(formDataWrapper),
		};
	}

	private getGeneralInfo(formDataWrapper: FormDataWrapper): PrivateSellerInfo['general'] {
		return {
			first_name: formDataWrapper.getString('first_name'),
			last_name: formDataWrapper.getString('last_name'),
			company: null,
			phone: formDataWrapper.getString('phone'),
			email: formDataWrapper.getString('email'),
			contact_type: null,
			date_of_birth: this.formatDateOfBirth(
				formDataWrapper.getString('date_of_birth_year'),
				formDataWrapper.getString('date_of_birth_month'),
				formDataWrapper.getString('date_of_birth_day'),
			),
			seller_type: formDataWrapper.getString('seller_type') as SellerType,
		};
	}

	private getAddressInfo(formDataWrapper: FormDataWrapper): PrivateSellerInfo['address'] {
		return {
			address1: formDataWrapper.getString('address1'),
			address2: formDataWrapper.getNullableString('address2'),
			address3: null,
			city: formDataWrapper.getString('city'),
			civic_number: formDataWrapper.getString('civic_number'),
			country: formDataWrapper.getString('country'),
			postal_code: formDataWrapper.getString('postal_code'),
			state: formDataWrapper.getString('state'),
			address_type: null,
		};
	}

	private getBankInfo(formDataWrapper: FormDataWrapper): PrivateSellerInfo['bank'] {
		return {
			bank_account_holder: formDataWrapper.getString('bank_account_holder'),
			bank_name: formDataWrapper.getString('bank_name'),
			bic_swift: formDataWrapper.getString('bic_swift'),
			iban: formDataWrapper.getString('iban'),
		};
	}

	private async submitData(
		privateSellerInfo: PrivateSellerInfo,
	): Promise<{ redirect: string; success: boolean; message: string }> {
		const response = await fetch('/api/seller/info.php', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(privateSellerInfo),
		});

		return response.json();
	}
}
