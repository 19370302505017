import * as m from '../../../../src/paraglide/messages';
import { max_upload_size } from './general';
import { z } from 'zod';
import { AddressType, ContactType, SellerType } from '../models/enums/general';
import { isValidIBAN, isValidBIC } from 'ibantools';

const fileValidation = (file: File, ctx: z.RefinementCtx, fieldName: string) => {
	if (!file || !file.size) return;
	if (file.size > max_upload_size) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: m.field_name_file_too_big({
				fieldName: fieldName,
				maxUploadSize: (max_upload_size / 1024 / 1024).toFixed(2),
			}),
		});
	} else if (!file.type.startsWith('image/')) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: m.field_name_not_an_image({ fieldName }),
		});
	}
};

export const signInSchema = z.object({
	email: z.string().trim().email(m.auth_invalid_email()),
	password: z.string().min(6, m.auth_invalid_password()).trim(),
});

export const signUpSchema = z.object({
	firstName: z.string().min(1, m.auth_invalid_name()).trim(),
	lastName: z.string().min(1, m.auth_invalid_surname()).trim(),
	email: z.string().trim().max(50, m.email_too_long()).email(m.auth_invalid_email()),
	password: z.string().min(6, m.auth_invalid_password()).trim(),
	privacy: z
		.string()
		.nullish()
		.refine((value: string | null | undefined) => value === 'on', m.invalid_privacy()),
});

export const resetPasswordSchema = z.object({
	email: z.string().email(m.auth_invalid_email()).trim(),
});

export const updateDetailsSchema = z.object({
	first_name: z.string().min(1, m.auth_invalid_name()).trim(),
	last_name: z.string().min(1, m.auth_invalid_surname()).trim(),
});

export const updatePasswordSchema = z.object({
	current_password: z.string().min(6, m.auth_invalid_password()).trim(),
	new_password: z.string().min(6, m.auth_invalid_password()).trim(),
	confirm_password: z.string().min(6, m.auth_invalid_password()).trim(),
});

export const checkoutSchema = z
	.object({
		certified_id: z
			.string()
			.optional()
			.nullish()
			.refine(
				(value: string | null | undefined) => (value ?? '').length <= 60,
				m.certified_id_too_long(),
			),
		tax_id: z.string().min(1, m.tax_id_required()).max(20, m.tax_id_too_long()).trim(),
		email: z.string().trim().max(50, m.email_too_long()).email(m.auth_invalid_email()),
		company: z.string().optional().nullish(),
	})
	.superRefine((data, ctx) => {
		//if company is not empty, certified_id is required
		if (data.company) {
			if (!data.certified_id || data.certified_id.trim().length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['certified_id'],
					message: m.certified_id_required(),
				});
			} else if (data.certified_id.trim().length > 60) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['certified_id'],
					message: m.certified_id_too_long(),
				});
			}
		}
	});

export const userTaxInformationSchema = z
	.object({
		certified_id: z
			.string()
			.optional()
			.nullish()
			.refine(
				(value: string | null | undefined) => (value ?? '').length <= 60,
				m.certified_id_too_long(),
			),
		tax_id: z.string().min(1, m.tax_id_required()).max(20, m.tax_id_too_long()).trim(),
		company: z.string().optional().nullish(),
	})
	.superRefine((data, ctx) => {
		//if company is not empty, certified_id is required
		if (data.company) {
			if (!data.certified_id || data.certified_id.trim().length === 0) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['certified_id'],
					message: m.certified_id_required(),
				});
			} else if (data.certified_id.trim().length > 60) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					path: ['certified_id'],
					message: m.certified_id_too_long(),
				});
			}
		}
	});

export const addressSchema = z.object({
	address1: z.string().min(1, m.address_required()).max(255, m.address_too_long()).trim(),
	address2: z
		.string()
		.optional()
		.nullish()
		.refine((value) => !value || value.length <= 255, m.address_too_long()),
	address3: z
		.string()
		.optional()
		.nullish()
		.refine((value) => !value || value.length <= 255, m.address_too_long()),
	state: z.string().min(1, m.state_required()).max(100, m.state_too_long()).trim(),
	city: z.string().min(1, m.city_required()).max(100, m.city_too_long()).trim(),
	postal_code: z
		.string()
		.min(1, m.postal_code_required())
		.max(20, m.postal_code_too_long())
		.trim(),
});

const baseContactSchema = z.object({
	first_name: z.string().min(1, m.auth_invalid_name()).max(100, m.name_too_long()).trim(),
	last_name: z.string().min(1, m.auth_invalid_surname()).max(100, m.surname_too_long()).trim(),
	company: z
		.string()
		.optional()
		.nullish()
		.refine(
			(value: string | null | undefined) => (value ?? '').length <= 100,
			m.company_too_long(),
		),
	email: z.string().optional().nullish(),
	phone: z.string().min(1, m.phone_required()).max(25, m.phone_too_long()).trim(),
});

export const contactSchema = baseContactSchema.superRefine((data, ctx) => {
	if (data.email) {
		// Trim the email
		data.email = data.email.trim();
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		// Check if email is too long
		if (data.email.length > 50) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				path: ['email'],
				message: m.email_too_long(),
			});
		}

		//check if email valid
		if (!emailRegex.test(data.email)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				path: ['email'],
				message: m.auth_invalid_email(),
			});
		}
	}
});

export const SellerDataSchema = z.object({
	size: z.string().min(1, m.seller_size_required()).max(100, m.seller_size_too_long()).trim(),
	name: z.string().min(2, m.seller_name_required()).max(30, m.seller_name_too_long()).trim(),
	motivation: z
		.string()
		.nullish()
		.refine(
			(value: string | undefined | null) => (value ?? '').length <= 100,
			m.seller_motivation_too_long(),
		),
});

export const SellerAddressSchema = z.object({
	address: addressSchema,
	email: z.string().max(50, m.seller_email_too_long()).email(m.auth_invalid_email()).trim(),
	phone: z.string().min(1, m.seller_phone_required()).max(25, m.seller_phone_too_long()).trim(),
});

export const SellerFilesSchema = z.object({
	logo: z.any().superRefine((file, ctx) => {
		fileValidation(file, ctx, m.seller_logo_field_name());
	}),
	banner: z.any().superRefine((file, ctx) => {
		fileValidation(file, ctx, m.seller_banner_field_name());
	}),
});

export const SellerGeneralInfoSchema = baseContactSchema.extend({
	date_of_birth: z
		.string()
		.min(1, m.date_required())
		.max(10, m.invalid_date())
		.refine((value) => {
			const [day, month, year] = value.split('-');
			const date = new Date(`${year}-${month}-${day}`);
			return !isNaN(date.getTime()) && date < new Date();
		}, m.invalid_date()),
	seller_type: z
		.string()
		.refine(
			(val) => Object.values(SellerType).includes(val as SellerType),
			m.invalid_seller_type(),
		),
});

export const sellerInfoAddressSchema = addressSchema.extend({
	country: z.string().min(1, m.country_required()).max(100, m.country_too_long()).trim(),
	civic_number: z
		.string()
		.min(1, m.civic_number_required())
		.max(20, m.civic_number_too_long())
		.trim(),
});

export const SellerBankInfoSchema = z.object({
	bank_account_holder: z
		.string()
		.min(1, m.bank_account_holder_required())
		.max(100, m.bank_account_holder_too_long())
		.trim(),
	bic_swift: z
		.string()
		.trim()
		.refine((value) => isValidBIC(value), {
			message: m.bic_swift_invalid(),
		}),
	iban: z
		.string()
		.trim()
		.refine((value) => isValidIBAN(value), {
			message: m.iban_invalid(),
		}),
});

export function isValidAddressType(value: string): value is AddressType {
	return Object.values(AddressType).includes(value as AddressType);
}

export function isValidContactType(value: string): value is ContactType {
	return Object.values(ContactType).includes(value as ContactType);
}
