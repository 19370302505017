import { AbstractFormHandler } from './abstractFormHandler';
import { ZodError } from 'zod';
import {
	Address,
	AddressWithContactAndUserTaxInformation,
	Contact,
	UserTaxInformation,
} from '../../models/interfaces/general';
import {
	addressSchema,
	contactSchema,
	isValidAddressType,
	isValidContactType,
	userTaxInformationSchema,
} from '../../utils/validation';
import { AuthService } from '../auth';
import { AddressType, ContactType } from '../../models/enums/general';
import { FormDataWrapper } from '../../utils/FormDataWrapper';

export class UserAddressFormHandler extends AbstractFormHandler {
	private authService: AuthService;

	constructor(authService: AuthService) {
		super();
		this.authService = authService;
	}

	public async submitForm(event: Event): Promise<void> {
		event.preventDefault();

		const form = event.target as HTMLFormElement;
		this.removeAlerts(form);
		const { loadingBtn, submitBtn } = this.showLoadingIndicators(form);

		const addressDataWithContactAndUserTaxInformation = this.getFormData(form);

		try {
			const validationSchemas = [
				{
					schema: addressSchema,
					data: addressDataWithContactAndUserTaxInformation.address,
				},
				{
					schema: contactSchema,
					data: addressDataWithContactAndUserTaxInformation.contact,
				},
				addressDataWithContactAndUserTaxInformation.contact.contact_type ===
				ContactType.Billing
					? {
							schema: userTaxInformationSchema,
							data: {
								...addressDataWithContactAndUserTaxInformation.userTaxInformation,
								company:
									addressDataWithContactAndUserTaxInformation.contact.company,
							},
						}
					: {
							schema: null,
							data: null,
						},
			];

			const zodError: ZodError = await this.superValidate(...validationSchemas);

			if (zodError.issues.length > 0) {
				throw zodError;
			}

			const responseData = await this.submitData(addressDataWithContactAndUserTaxInformation);

			if (!responseData.success) {
				throw new Error(responseData.message);
			}

			if (responseData.redirect) {
				window.location.href = responseData.redirect;
			}

			this.displaySuccessMessage(form, responseData.message);
		} catch (error) {
			if (error instanceof ZodError) {
				this.createAlertForEachInput(form, error);
				form.scrollIntoView({ behavior: 'smooth', block: 'start' });
			} else {
				this.displayErrorMessage(form, this.extractErrorMessage(error));
			}
		} finally {
			this.hideLoadingIndicators(loadingBtn, submitBtn);
		}
	}

	private getFormData(form: HTMLFormElement): AddressWithContactAndUserTaxInformation {
		const formDataWrapper: FormDataWrapper = new FormDataWrapper(form);

		const TypeValue = formDataWrapper.getString('type');

		if (!isValidAddressType(TypeValue) || !isValidContactType(TypeValue)) {
			throw new Error('Invalid type value');
		}

		const contactData: Contact = {
			contact_type: TypeValue as ContactType,
			first_name: formDataWrapper.getString('first_name'),
			last_name: formDataWrapper.getString('last_name'),
			company: formDataWrapper.getNullableString('company'),
			email: formDataWrapper.getString('email'),
			phone: formDataWrapper.getString('phone'),
		};

		const addressData: Address = {
			address_type: TypeValue as AddressType,
			address1: formDataWrapper.getString('address1'),
			address2: formDataWrapper.getNullableString('address2'),
			address3: formDataWrapper.getNullableString('address3'),
			state: formDataWrapper.getString('state'),
			city: formDataWrapper.getString('city'),
			postal_code: formDataWrapper.getString('postal_code'),
		};

		const userTaxInformation: UserTaxInformation = {
			certified_id: formDataWrapper.getNullableString('certified_id'),
			tax_id: formDataWrapper.getString('tax_id'),
		};

		const addressDataWithContactAndUserTaxInformation: AddressWithContactAndUserTaxInformation =
			{
				address: addressData,
				contact: contactData,
				userTaxInformation: userTaxInformation,
			};

		return addressDataWithContactAndUserTaxInformation;
	}

	private async submitData(
		addressDataWithContactAndUserTaxInformation: AddressWithContactAndUserTaxInformation,
	): Promise<{ redirect: string; success: boolean; message: string }> {
		const idToken = await this.authService.getIdToken();
		const userId = this.authService.getUid();

		const response = await fetch('/api/user/updateAddress.php', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${idToken}`,
			},
			body: JSON.stringify({
				userId,
				addressDataWithContactAndUserTaxInformation,
			}),
		});

		return response.json();
	}
}
