import type { AuthService } from '../auth';
import * as m from '../../../../../src/paraglide/messages';
import {
	signInSchema,
	signUpSchema,
	resetPasswordSchema,
	updateDetailsSchema,
	updatePasswordSchema,
} from '../../utils/validation';
import { AbstractFormHandler } from './abstractFormHandler';
import { FormDataWrapper } from '../../utils/FormDataWrapper';

export class AuthHandler extends AbstractFormHandler {
	private authService: AuthService;

	constructor(authService: AuthService) {
		super();
		this.authService = authService;
	}

	public async loginFormSubmitHandler(event: CustomEvent): Promise<void> {
		const form: HTMLFormElement | undefined = event.detail?.form;

		if (!form) return;

		const formDataWrapper: FormDataWrapper = new FormDataWrapper(form);

		const { loadingBtn, submitBtn } = this.showLoadingIndicators(form);

		try {
			const { email, password } = await signInSchema.parseAsync({
				email: formDataWrapper.getString('email'),
				password: formDataWrapper.getString('password'),
			});

			await this.authService.signIn(email, password);
			const rememberMe: boolean | undefined = (
				form.querySelector('input[name="rememberMe"]') as HTMLInputElement
			)?.checked;

			if (!rememberMe) {
				await this.authService.setPersistenceToBrowserSession();
			}

			await new Promise((resolve) => setTimeout(resolve, 500));
			window.location.reload();
		} catch (error) {
			const message = this.extractErrorMessage(error);
			this.displayErrorMessage(form, message);

			this.hideLoadingIndicators(loadingBtn, submitBtn);
		}
	}

	public async loginWithGoogleHandler(event: CustomEvent): Promise<void> {
		const form: HTMLFormElement | undefined = event?.detail?.form;

		try {
			await this.authService.signInWithGoogle();
			await new Promise((resolve) => setTimeout(resolve, 500));
			window.location.reload();
		} catch (error) {
			const message = this.extractErrorMessage(error);

			if (form) {
				this.displayErrorMessage(form, message);
			} else {
				alert(message);
			}
		}
	}

	public async resetPasswordHandler(event: CustomEvent): Promise<void> {
		const form: HTMLFormElement | undefined = event.detail?.form;

		if (!form) return;

		const formDataWrapper: FormDataWrapper = new FormDataWrapper(form);

		const { loadingBtn, submitBtn } = this.showLoadingIndicators(form);

		try {
			const { email } = resetPasswordSchema.parse({
				email: formDataWrapper.getString('email'),
			});

			await this.authService.resetPassword(email);
			setTimeout(() => {
				this.hideLoadingIndicators(loadingBtn, submitBtn);
				this.displaySuccessMessage(form, m.reset_password_success());
			}, 500);
		} catch (error) {
			const message = this.extractErrorMessage(error);
			this.displayErrorMessage(form, message);

			this.hideLoadingIndicators(loadingBtn, submitBtn);
		}
	}

	public async signUpFormSubmitHandler(event: CustomEvent): Promise<void> {
		const form: HTMLFormElement | undefined = event.detail?.form;

		if (!form) return;

		const formDataWrapper: FormDataWrapper = new FormDataWrapper(form);

		const { loadingBtn, submitBtn } = this.showLoadingIndicators(form);

		try {
			const { firstName, lastName, email, password } = signUpSchema.parse({
				firstName: formDataWrapper.getString('firstName'),
				lastName: formDataWrapper.getString('lastName'),
				email: formDataWrapper.getString('email'),
				password: formDataWrapper.getString('password'),
				privacy: formDataWrapper.getString('privacy'),
			});

			//get newsletter checked or not or contains class checked
			const newsletterToggle = form.querySelector('#newsletter') as HTMLInputElement;
			const newsletterToggled =
				newsletterToggle.checked || newsletterToggle.classList.contains('checked') || false;

			const displayName = `${firstName} ${lastName}`;
			const signInMethods = await this.authService.signUp(email, password, displayName);

			if (Array.isArray(signInMethods)) {
				let provider;
				if (signInMethods.includes('google.com')) {
					provider = this.authService.getProviderFromMap('google.com');
				} else if (signInMethods.includes('facebook.com')) {
					provider = this.authService.getProviderFromMap('facebook.com');
				} else {
					throw new Error(m.unknown_error());
				}

				const providerName =
					provider.providerId.split('.')[0].charAt(0).toUpperCase() +
					provider.providerId.split('.')[0].slice(1);
				const confirmSignIn = await window.showProviderModal(
					providerName,
					`Esiste già un account con questa email. Accedi con ${providerName} per collegare gli account, altrimenti registrati con un indirizzo email diverso.`,
				);

				if (confirmSignIn) {
					await this.authService.linkWithExistingProvider(
						provider,
						email,
						password,
						displayName,
					);
					if (newsletterToggled) {
						try {
							await this.authService.subscribeToNewsletter();
						} catch (error) {
							console.error('Error subscribing to newsletter', error);
						}
					}
					window.location.reload();
				} else {
					throw new Error(m.sign_up_link_cancelled());
				}
			} else {
				if (this.authService.isLoggedIn()) {
					await this.authService.setPersistenceToBrowserSession();
				}

				if (newsletterToggled) {
					try {
						await this.authService.subscribeToNewsletter();
					} catch (error) {
						console.error('Error subscribing to newsletter', error);
					}
				}

				await new Promise((resolve) => setTimeout(resolve, 500));
				window.location.reload();
			}
		} catch (error) {
			const message = this.extractErrorMessage(error);
			this.displayErrorMessage(form, message);

			this.hideLoadingIndicators(loadingBtn, submitBtn);
		}
	}

	public async updateAccountDetailsHandler(event: CustomEvent): Promise<void> {
		const form: HTMLFormElement | undefined = event.detail?.form;

		if (!form) return;

		const formDataWrapper: FormDataWrapper = new FormDataWrapper(form);

		const { loadingBtn, submitBtn } = this.showLoadingIndicators(form);

		try {
			const firstName = formDataWrapper.getString('first_name');
			const lastName = formDataWrapper.getString('last_name');
			const currentPassword = formDataWrapper.getString('current_password');
			const newPassword = formDataWrapper.getString('new_password');
			const confirmPassword = formDataWrapper.getString('confirm_password');

			const shouldUpdateDisplayName =
				(firstName && firstName.trim() !== '') || (lastName && lastName.trim() !== '');
			const shouldUpdatePassword =
				(currentPassword && currentPassword.trim() !== '') ||
				(newPassword && newPassword.trim() !== '') ||
				(confirmPassword && confirmPassword.trim() !== '');

			const validations = [];
			if (shouldUpdateDisplayName) {
				validations.push({
					schema: updateDetailsSchema,
					data: { first_name: firstName, last_name: lastName },
				});
			}

			if (shouldUpdatePassword) {
				validations.push({
					schema: updatePasswordSchema,
					data: {
						current_password: currentPassword,
						new_password: newPassword,
						confirm_password: confirmPassword,
					},
				});
			}

			const zodError = await this.superValidate(...validations);

			if (zodError.issues.length > 0) {
				throw zodError;
			}

			if (shouldUpdateDisplayName) {
				const displayName = `${firstName} ${lastName}`;
				await this.authService.updateUserDisplayName(displayName);
			}

			if (shouldUpdatePassword) {
				if (newPassword !== confirmPassword) {
					throw new Error(m.password_mismatch());
				}

				await this.authService.updateUserPassword(currentPassword, newPassword);
			}

			setTimeout(() => {
				this.hideLoadingIndicators(loadingBtn, submitBtn);
				this.displaySuccessMessage(form, m.account_details_update_success());
			}, 500);
		} catch (error) {
			const message = this.extractErrorMessage(error);
			this.displayErrorMessage(form, message);

			this.hideLoadingIndicators(loadingBtn, submitBtn);
		}
	}

	public async unsubscribeFromNewsletter(): Promise<void> {
		try {
			await this.authService.unsubscribeFromNewsletter();
		} catch (error) {
			document.cookie = `error=${m.unsubscribe_from_newsletter_failed()}; path=/`;
			console.error('Error unsubscribing from newsletter:', error);
		}

		await new Promise((resolve) => setTimeout(resolve, 500));
		window.location.reload();
	}
}
