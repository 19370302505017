export class FormDataWrapper {
	protected formData: FormData;

	constructor(form: HTMLFormElement | undefined) {
		this.formData = new FormData(form);
	}

	getNullableString(key: string): string | null {
		return this.get(key);
	}

	getString(key: string): string {
		return this.getNullableString(key) ?? '';
	}

	getBoolean(key: string): boolean {
		const value = this.get(key);
		return value === 'true';
	}

	getNullableNumber(key: string): number | null {
		const value = this.get(key);
		return value === null ? null : Number(value);
	}

	getNumber(key: string): number {
		return this.getNullableNumber(key) ?? 0;
	}

	getNullableDate(key: string): Date | null {
		const value = this.get(key);
		return value === null ? null : new Date(value);
	}

	getDate(key: string): Date {
		return this.getNullableDate(key) ?? new Date();
	}

	getNullableFile(key: string): File | null {
		const value = this.formData.get(key);
		return value instanceof File && value.size > 0 ? value : null;
	}

	getFile(key: string): File {
		return this.getNullableFile(key) ?? new File([], '');
	}

	getFormData(): FormData {
		return this.formData;
	}

	private get(key: string): string | null {
		const value = this.formData.get(key);
		return value === '' ? null : (value as string | null);
	}
}
