export enum AddressType {
	Billing = 'billing',
	Shipping = 'shipping',
}

export enum ContactType {
	Billing = 'billing',
	Shipping = 'shipping',
}

export enum PaymentMethod {
	CreditCard = 'CREDIT_CARD',
	BankTransfer = 'BANK_TRANSFER',
}

export enum SellerType {
	Company = 'Azienda',
	IndependentWithVat = 'Venditore autonomo con P.IVA',
}
