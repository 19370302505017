import { ZodError, ZodIssue, ZodSchema } from 'zod';
import * as m from '../../../../../src/paraglide/messages';
import { getFirebaseErrorMessage, mapFieldNames } from '../../utils/general';
import { FirebaseError } from 'firebase/app';

export abstract class AbstractFormHandler {
	protected createAlertForEachInput(form: HTMLFormElement, error: ZodError): void {
		const errorMap = new Map<string, string[]>();

		error.errors.forEach((err) => {
			const fieldName = err.path[0] as string;
			if (!errorMap.has(fieldName)) {
				errorMap.set(fieldName, []);
			}
			errorMap.get(fieldName)!.push(err.message);
		});

		errorMap.forEach((messages, fieldName) => {
			if (fieldName.startsWith('date_of_birth')) {
				this.handleDateOfBirthError(form, messages);
			} else {
				const input = form.querySelector(`[name="${fieldName}"]`);
				if (!input) return;

				const alert = this.createAlertElement(messages.join(', '));

				if (input instanceof HTMLInputElement && input.type === 'radio') {
					this.handleRadioError(input, alert);
				} else {
					this.handleDefaultError(input, alert);
				}

				this.styleInvalidInput(input);
			}
		});
	}

	private handleDateOfBirthError(form: HTMLFormElement, messages: string[]): void {
		const dateGroup = form.querySelector('.date-of-birth-group');
		if (dateGroup && !dateGroup.querySelector('.custom-error-message')) {
			const alert = this.createAlertElement(messages.join(', '));
			dateGroup.appendChild(alert);

			dateGroup.querySelectorAll('select').forEach((input) => {
				this.styleInvalidInput(input);
			});
		}
	}

	private createAlertElement(message: string): HTMLDivElement {
		const alert = document.createElement('div');
		const icon = document.createElement('i');

		alert.classList.add(
			'alert',
			'alert-icon',
			'alert-error',
			'alert-bg',
			'alert-inline',
			'show-code-action',
			'mb-3',
			'custom-error-message',
		);
		icon.classList.add('icon', 'w-icon-times-circle');

		alert.appendChild(icon);
		alert.appendChild(document.createTextNode(message));

		return alert;
	}

	private handleRadioError(input: HTMLInputElement, alert: HTMLDivElement): void {
		const radioGroup = input.closest('.form-group');
		if (radioGroup && !radioGroup.querySelector('.custom-error-message')) {
			radioGroup.appendChild(alert);
		}
	}

	private handleDefaultError(input: Element, alert: HTMLDivElement): void {
		if (!input.nextElementSibling?.classList.contains('custom-error-message')) {
			input.insertAdjacentElement('afterend', alert);
		}
	}

	private styleInvalidInput(input: Element): void {
		if (
			input instanceof HTMLInputElement ||
			input instanceof HTMLSelectElement ||
			input instanceof HTMLTextAreaElement
		) {
			input.style.border = '1px solid red';
		}
	}

	protected displayErrorMessage(form: HTMLFormElement, message: string): void {
		let errorContainer: HTMLElement | null = form.querySelector('.alert-error');

		if (!errorContainer) {
			errorContainer = document.createElement('div');
			errorContainer.classList.add(
				'alert',
				'alert-icon',
				'alert-error',
				'alert-bg',
				'alert-block',
				'alert-inline',
				'show-code-action',
				'd-none',
			);
			form.prepend(errorContainer);
		}

		errorContainer.innerHTML = `
            <h4 class="alert-title">
                <i class="w-icon-exclamation-triangle"></i>Oops!</h4>
            ${m.auth_error_heading()}
            <p>${message.replace(/\n/g, '<br>')}</p>
            <button class="btn btn-link btn-close" aria-label="button">
                <i class="close-icon"></i>
            </button>
        `;

		errorContainer.classList.remove('d-none');
	}

	protected displaySuccessMessage(form: HTMLFormElement, message: string): void {
		let successContainer: HTMLElement | null = form.querySelector('.alert-success');

		if (!successContainer) {
			successContainer = document.createElement('div');
			successContainer.classList.add(
				'alert',
				'alert-icon',
				'alert-success',
				'alert-bg',
				'alert-block',
				'alert-inline',
				'show-code-action',
				'd-none',
			);
			form.prepend(successContainer);
		}

		successContainer.innerHTML = `
            <h4 class="alert-title">
                <i class="w-icon-check"></i>Success!</h4>
            ${message}
            <button class="btn btn-link btn-close" aria-label="button">
                <i class="close-icon"></i>
            </button>
        `;

		successContainer.classList.remove('d-none');
	}

	protected extractErrorMessage(error: unknown): string {
		if (error instanceof ZodError) {
			return error.errors.map((err) => err.message).join('\n');
		}
		if (error instanceof FirebaseError) {
			return getFirebaseErrorMessage(error.code);
		}
		if (error instanceof Error) {
			return error.message;
		}
		return 'Unknown error occurred';
	}

	protected hideLoadingIndicators(
		loadingBtn: HTMLElement | null,
		submitBtn: HTMLElement | null,
	): void {
		if (loadingBtn && submitBtn) {
			loadingBtn.classList.add('d-none');
			submitBtn.classList.remove('d-none');
		}
	}

	protected removeAlerts(form: HTMLFormElement): void {
		const alerts = form.querySelectorAll('.alert-error, .custom-error-message');
		alerts.forEach((alert) => alert.remove());
		const inputs = form.querySelectorAll('input, select, textarea');
		inputs.forEach((input) => {
			if (
				input instanceof HTMLInputElement ||
				input instanceof HTMLSelectElement ||
				input instanceof HTMLTextAreaElement
			) {
				input.style.border = '';
			}
		});
	}

	protected showLoadingIndicators(form: HTMLFormElement): {
		loadingBtn: HTMLElement | null;
		submitBtn: HTMLElement | null;
	} {
		const loadingBtn: HTMLElement | null = form.querySelector('.spinner-button');
		const submitBtn: HTMLElement | null = form.querySelector('button[type="submit"]');

		if (loadingBtn && submitBtn) {
			loadingBtn.classList.remove('d-none');
			submitBtn.classList.add('d-none');
		}

		return { loadingBtn, submitBtn };
	}

	protected async superValidate(
		...schemas: { schema: ZodSchema<unknown>; data: unknown; prefix?: string }[]
	) {
		const zodError: ZodError = new ZodError([]);

		for (const { schema, data, prefix = null } of schemas) {
			if (schema === null) continue;
			try {
				await schema.parseAsync(data);
			} catch (error) {
				if (error instanceof ZodError) {
					const mappedIssues = mapFieldNames(prefix, error.issues);
					for (const issue of mappedIssues) {
						zodError.issues.push(issue);
					}

					zodError.issues = this.flattenIssues(zodError.issues);
				} else {
					throw error; // Rethrow non-Zod errors
				}
			}
		}

		return zodError;
	}

	private flattenIssues(issues: ZodIssue[]): ZodIssue[] {
		return issues.map((issue) => ({
			...issue,
			path: [issue.path.join('.')],
		}));
	}
}
