import { AbstractFormHandler } from './abstractFormHandler';
import { SellerDataSchema, SellerAddressSchema, SellerFilesSchema } from '../../utils/validation';
import { ZodError } from 'zod';
import { SellerAddress, SellerData, SellerFiles } from '../../models/interfaces/general';
import { FormDataWrapper } from '../../utils/FormDataWrapper';

export class RegisterSellerFormHandler extends AbstractFormHandler {
	public async submitForm(event: Event): Promise<void> {
		event.preventDefault();

		const form = event.target as HTMLFormElement;
		this.removeAlerts(form);
		const { loadingBtn, submitBtn } = this.showLoadingIndicators(form);

		const sellerData: SellerData = this.getFormData(form);
		const sellerAddress: SellerAddress = this.getSellerAddress(form);
		const sellerFiles: SellerFiles = this.getSellerFiles(form);

		try {
			const zodError: ZodError = await this.superValidate(
				{ schema: SellerDataSchema, data: sellerData },
				{ schema: SellerAddressSchema, data: sellerAddress },
				{ schema: SellerFilesSchema, data: sellerFiles },
			);

			if (zodError.issues.length > 0) {
				throw zodError;
			}

			const response = await this.submitData(sellerData, sellerAddress);

			if (!response.success) {
				throw new Error(response.message);
			}

			if (sellerFiles.logo || sellerFiles.banner) {
				await this.uploadFiles(sellerFiles, response.sellerId).catch((error) => {
					console.error('Error uploading files', error);
				});
			}

			document.body.dispatchEvent(new CustomEvent('finish-step-1'));
		} catch (error) {
			if (error instanceof ZodError) {
				this.createAlertForEachInput(form, error);
			} else {
				this.displayErrorMessage(form, this.extractErrorMessage(error));
			}
		} finally {
			this.hideLoadingIndicators(loadingBtn, submitBtn);
		}
	}

	private getFormData(form: HTMLFormElement): SellerData {
		const formDataWrapper: FormDataWrapper = new FormDataWrapper(form);

		return {
			size: formDataWrapper.getString('size'),
			name: formDataWrapper.getString('name'),
			motivation: formDataWrapper.getString('motivation'),
		};
	}

	private getSellerAddress(form: HTMLFormElement): SellerAddress {
		const formDataWrapper: FormDataWrapper = new FormDataWrapper(form);

		return {
			address: {
				address1: formDataWrapper.getString('address.address1'),
				address2: formDataWrapper.getNullableString('address.address2'),
				address3: formDataWrapper.getNullableString('address.address3'),
				state: formDataWrapper.getString('address.state'),
				city: formDataWrapper.getString('address.city'),
				postal_code: formDataWrapper.getString('address.postal_code'),
				address_type: null,
			},
			email: formDataWrapper.getNullableString('email'),
			phone: formDataWrapper.getNullableString('phone'),
		};
	}

	private getSellerFiles(form: HTMLFormElement): SellerFiles {
		const formDataWrapper: FormDataWrapper = new FormDataWrapper(form);

		return {
			logo: formDataWrapper.getNullableFile('logo'),
			banner: formDataWrapper.getNullableFile('banner'),
		};
	}

	private async submitData(
		sellerData: SellerData,
		sellerAddress: SellerAddress,
	): Promise<{ success: boolean; message: string; sellerId: number }> {
		const response = await fetch('/api/seller/create.php', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				sellerData,
				sellerAddress,
			}),
		});

		return response.json();
	}

	private async uploadFiles(sellerFiles: SellerFiles, sellerId: number): Promise<void> {
		const formData = new FormData();
		formData.append('logo', sellerFiles.logo);
		formData.append('banner', sellerFiles.banner);
		formData.append('sellerId', sellerId.toString());

		const response = await fetch('/api/seller/upload.php', {
			method: 'POST',
			body: formData,
		});

		return response.json();
	}
}
